.client-slider .item img,
.service-slider .item img {
  object-fit: cover;
  height: 160px;
  width: 200px;
  margin: 0 auto;
}
.service-link,
.vvd span,
a,
a:hover,
button,
li,
p,
ul {
  text-decoration: none;
}
a,
button,
h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
ul {
  margin: 0;
  line-height: normal;
  padding: 0;
}
.banner .tagline,
.social-icon {
  display: inline-block;
  margin-bottom: 10px !important;
}
.banner .tagline,
.banner h1,
.banner p {
  letter-spacing: 0.8px;
}
.service-link,
body {
  color: #fff !important;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}
body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  font-family: Centra, sans-serif !important;
}
img {
  width: 100%;
  height: auto;
}
button {
  border: 0;
  background-color: transparent;
}
input:focus,
select:focus,
textarea:focus {
  outline: 0;
}
@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}
p.success {
  color: green;
}
p.danger {
  color: red;
}
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}
/* nav.navbar.scrolled { */
nav.navbar {
  padding: 0;
  background: rgba(0 0 0 / 35%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
nav.navbar a.navbar-brand {
  width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 700;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 15px;
  font-size: 15px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link.active,
nav.navbar .navbar-nav a.nav-link.navbar-link:hover {
  opacity: 1;
  text-decoration: underline;
  font-size: 16px;
}
span.navbar-text {
  display: flex;
  align-items: center;
}
.social-icon a {
  width: 30px;
  height: 30px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.navbar-text button,
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(0.7);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 15px 24px;
  font-size: 16px;
  margin-left: 10px;
  position: relative;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: 0;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: 0.3s linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: 0.3s linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}
.banner {
  margin-top: 0;
  padding: 260px 0 100px;
  background-image: url("./assets/img/banner-bg1.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, #113250 -5.91%, #393354 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
}
.banner h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #fff;
  font-size: 18px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.about p,
.project p,
.skill p {
  color: #fff;
  line-height: 1.5em;
  letter-spacing: 0.8px;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner .bannerImg {
  animation: 3s linear infinite updown;
}
.banner .psb {
  border-radius: 50%;
  background-color: #fff;
  height: 120px;
  width: 120px;
  display: flex;
  margin: auto;
}
@keyframes updown {
  0%,
  100% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}
.skill {
  padding: 0 0 50px;
  position: relative;
}
.skill-bx {
  background: #2b2b2b;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.about,
.about-column,
.project,
.services {
  background-color: #000;
}
.skill h2 {
  font-size: 45px;
  font-weight: 700;
}
.skill p {
  font-size: 18px;
  margin: 14px 0 75px;
}
.skill-slider {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}
.progress-bar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.progress-bar-container {
  margin-bottom: 10px;
  width: 150px;
  height: 150px;
}
.about {
  position: relative;
}
.about h2,
.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}
.about p {
  font-size: 18px;
  margin: 34px 0 30px 5px;
  text-align: left;
  width: 56%;
}
.about-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}
.about-img {
  width: 200px;
  animation: 20s linear infinite spin;
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.about-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.client,
.project,
.services {
  padding: 80px 0;
  position: relative;
}
.about-icon {
  width: 150px;
}
.about-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.about-content img {
  width: 150px;
  height: 150px;
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.about-content img:hover {
  transform: perspective(1000px) rotateY(10deg);
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
}
.about-content p {
  flex: 2;
}
.service-slider .item h5,
.services p {
  align-items: center;
  text-align: center;
}
.client,
.services {
  background-image: linear-gradient(to bottom, #443e7f7e, #151515);
}
.client h2,
.services h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.services p {
  width: 80%;
}
.service-slider .item {
  width: 100%;
  box-sizing: border-box;
  margin-right: 10px;
}
.service-slider .item:last-child {
  margin-right: 0;
}
.service-slider .item img {
  display: block;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.325);
  cursor: pointer;
}
.vvd span {
  font-size: 14px;
}
.contact,
.proj-imgbx::before {
  background: linear-gradient(90.21deg, #113250 -5.91%, #3b73fffd 111.58%);
}
.new-email-bx::before,
.project .nav.nav-pills .nav-link::before {
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  content: "";
  z-index: -1;
}
.project p {
  font-size: 18px;
  margin: 14px auto 30px;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
  border: 1px solid #fff;
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0 0 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 24px;
  width: 200px;
  height: 160px;
}
.proj-imgbx img {
  object-fit: fill;
  height: 100%;
  width: 100%;
  object-position: center;
}
.proj-imgbx::before {
  content: "";
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}
.client-slider {
  width: 80%;
  position: relative;
}
.client-slider .item img {
  display: block;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.472);
}
.service-slider .item h5 {
  display: block;
  margin: 10px auto 0;
  max-width: 80%;
  border-radius: 5px;
}
.contact {
  padding: 60px 0 200px;
}
.contact img,
.contactP img {
  width: 92%;
}
.contact h2,
.contactP h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}
.contact form input,
.contact form textarea,
.contactP form input,
.contactP form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus,
.contact form textarea:focus,
.contactP form input:focus,
.contactP form textarea:focus {
  background: #fff;
  color: #121212;
}
.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button,
.contactP form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span,
.contactP form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover,
.contactP form button:hover {
  color: #fff;
}
.contact form button::before,
.contactP form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before,
.contactP form button:hover::before {
  width: 100%;
}
.footer {
  background-image: url("./assets/img/footer-bg1.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -15px;
  border: 1px solid #4a4a4a;
  border-top: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.566);
  border-radius: 20px 20px 0 0;
}
.new-email-bx,
.new-email-bx::after {
  background: #fff;
  border-radius: 20px;
}
.about-column,
.item,
.service-link img {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.newsletter-bx {
  background: #fff;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  padding: 5px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  border-radius: 20px;
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: 0 0;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.about-icon,
.service-link img {
  border-radius: 8px;
  height: auto;
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
.dropdown {
  color: #ffff;
}
.contact-info iframe {
  max-width: 100%;
}
.contactP {
  background: linear-gradient(90.21deg, #113250 -5.91%, #7e61ff 111.58%);
  padding: 60px 0;
}
.contactP form input::placeholder,
.contactP form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contactP form input:focus::placeholder,
.contactP form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact-info {
  margin-top: 20px !important;
}
.contact-info p {
  font-size: 18px !important;
}
.contact-info h4 {
  font-size: 15px !important;
}
.contact-heading {
  margin-top: 100px !important;
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 768px) {
  .about h2 {
    font-size: 36px;
  }
  .about-content,
  .about-row,
  .about-wrapper {
    flex-direction: column;
  }
  .about-img {
    height: 300px;
    width: 300px;
    margin-top: 20px;
  }
  .bannerImg {
    height: 250px !important;
    width: 300px !important;
    margin-left: 40px;
  }
  .about-img {
    margin-left: 70px;
  }
  .about-column {
    flex: 1;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .about-icon {
    width: 150px;
    height: 270px;
    margin-top: 50px;
  }
  .about-content img {
    margin: auto auto auto 10px;
    height: 300px;
    width: 300px;
  }
  .about .about-para,
  .about-content p {
    width: 100%;
    font-size: 18px;
    text-align: center;
  }
  .about-content p {
    margin-top: 10px;
  }
  .about .about-para {
    margin: auto auto auto 20px;
  }
  .services p {
    font-size: 18px;
    width: 95%;
    margin: auto auto 20px;
    align-items: center;
    text-align: center;
  }
  .banner h1,
  .goTop {
    font-size: 30px;
  }
  .service-slider .item img {
    width: 300px;
    height: 200px;
    margin-top: 50px;
  }
  .contact form button,
  .contactP form button {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
  .footer-img {
    margin-left: 55px;
  }
  .footer-links {
    text-decoration: none;
    color: #fff;
  }
  .goTop {
    line-height: 40px;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 200px;
    opacity: 0.5;
    transition: opacity 0.3s;
  }
  .goTop:hover {
    opacity: 1;
  }
  .contact-info iframe {
    width: 100%;
  }
  .c-img {
    display: none;
  }
  .c-img1 {
    margin-top: 30px;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  .bannerImg {
    height: 350px !important;
    width: 450px !important;
    margin-left: 60px;
  }
  .about-img {
    margin-left: 100px;
  }
  .about-row {
    justify-content: space-between;
  }
  .about-icon {
    width: 180px;
    height: 320px;
  }
  .about-content img {
    height: 360px;
    width: 360px;
    margin-left: 60px;
  }
  .about-content p {
    font-size: 20px;
    margin-top: 20px;
  }
  .about .about-para {
    margin-left: 40px;
    font-size: 20px;
  }
  .services p {
    font-size: 20px;
    width: 90%;
  }
  .service-slider .item img {
    width: 350px;
    height: 230px;
    margin-top: 40px;
  }
  .contact form button,
  .contactP form button {
    padding: 14px 36px;
  }
  .contact-info iframe {
    width: 100%;
    height: 300px;
  }
  .footer-img {
    margin-left: 75px;
  }
  .c-img1 {
    margin-top: 20px;
  }
}
.about {
  padding: 50px 5px;
  text-align: center;
}
.about h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #fff;
}
.about-wrapper {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}
.about-para {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #8b8b8b;
  margin-bottom: 20px;
}
.about-img {
  max-width: 100%;
  height: auto;
  margin: 20px auto;
  display: block;
}
.about-column h3,
.about-row,
.item {
  display: flex;
  display: flex;
}
.about-row {
  justify-content: center;
  gap: 30px;
  margin-top: 40px;
  flex-wrap: wrap;
}
.about-column {
  padding: 20px;
  flex: 1;
  min-width: 300px;
  max-width: 460px;
  border-radius: 8px;
  text-align: left;
}
.about-column h3 {
  font-size: 1.8rem;
  color: #fff;
  align-items: center;
  gap: 10px;
}
.about-icon {
  max-width: 100%;
  margin: 20px 0;
}
.about-content p {
  font-size: 1rem;
  line-height: 1.6;
  color: #fff;
}
.services {
  padding: 60px 0;
  text-align: center;
}
.services h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 700;
  color: #fff;
}
.services p {
  max-width: 800px;
  margin: 0 auto 40px;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #fff;
}
.client-slider,
.project-slider,
.service-slider {
  display: flex;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  overflow: hidden;
  padding: 20px 0;
}
.item:hover {
  transform: scale(1.05);
}
.service-link {
  color: inherit;
  transition: color 0.3s;
}
.service-link img {
  width: 100%;
  max-width: 150px;
  margin-bottom: 15px;
}
.item {
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  margin-left: 10px;
  border-radius: 12px;
  transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    box-shadow 0.4s cubic-bezier(0.19, 1, 0.22, 1), background-color 0.3s;
  position: relative;
  overflow: hidden;
}
.item::after,
.item::before {
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  background: linear-gradient(
    45deg,
    rgba(89, 153, 255, 0.2),
    rgba(1, 20, 104, 0.2)
  );
  transition: 0.4s;
  transform: rotate(45deg);
  z-index: -1;
}
.item::before {
  top: -100%;
  left: -100%;
}
.item::after {
  bottom: -100%;
  right: -100%;
}
.item:hover {
  transform: translateY(-5px) rotate(1deg);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.item:hover::before {
  top: 0;
  left: 0;
}
.item:hover::after {
  bottom: 0;
  right: 0;
}
h3 {
  color: #c3c3ff;
}
